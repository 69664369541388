var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
let VRAssetService = class VRAssetService {
    constructor() {
        this.listeners = {};
    }
    hasListener(elementId, type) {
        return (this.listeners[elementId] &&
            this.listeners[elementId].findIndex((listener) => listener.type === type) !== -1);
    }
    addListener(elementId, type, callback) {
        if (!this.listeners[elementId]) {
            this.listeners[elementId] = [];
        }
        this.removeListener(elementId, type);
        document.getElementById(elementId).addEventListener(type, callback);
        this.listeners[elementId].push({
            type,
            callback,
        });
    }
    removeListener(elementId, type) {
        if (!this.listeners[elementId]) {
            return;
        }
        const index = this.listeners[elementId].findIndex((listener) => listener.type === type);
        if (index === -1) {
            return;
        }
        document
            .getElementById(elementId)
            .removeEventListener(type, this.listeners[elementId][index].callback);
        this.listeners[elementId].splice(index, 1);
    }
};
VRAssetService = __decorate([
    Service()
], VRAssetService);
export { VRAssetService };
